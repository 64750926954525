.image-card {
  width: 30vw;
  height: 30vw;
  object-fit: cover;
}

#lightbox-img {
  height: 80vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

li.active.nav-item.menu-open {
  background: #fff; 
}
li.active.nav-item.menu-open a{ 
  color: black!important;
} 
.sidebar-dark-primary .sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active{
  background-color: #007bff!important;
  color: #fff!important;
}